import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
    registro: {},
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },

  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise(resolve => {
        axios
          .get('/status', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, idTipoRegistro) {
      return new Promise((resolve, reject) => {
        axios
          .get(`tipos-registro/${idTipoRegistro}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      console.log(itemData, 'itemData')
      return new Promise((resolve, reject) => {
        axios
          .post('/status', itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios.put(`/status/${itemData.uuid}`, itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/status/${id}`)
          .then(() => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
